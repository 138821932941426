// require('./form');
const form = require("../common/form");

$(document).on('change', '#instruction_test_attempt_item_form input', function () {
  var $this = $(this),
      inputType = $this.attr('type'),
      $form = $this.closest('#instruction_test_attempt_item_form'),
      isChecked = $this.is(':checked');

  if (inputType === 'radio') {
    $form.find('.answer-item--selected').removeClass('answer-item--selected');
    if (isChecked) { form.submit($form); }
  } else if (inputType === 'checkbox') {
    form.submit($form);
  }

  $this.closest('label').toggleClass('answer-item--selected', isChecked);
});


$(document).on('change', '#instruction_test_question_is_answers_as_images', function () {
  var $this = $(this),
      isAsImages = $this.is(':checked');

  changeTestQuestionAnswers($this, isAsImages)
});

$(document).on('change', '#instruction_test_question_is_multi_choice_answers', function () {
  var $this = $(this),
      isMultiAnswer = $this.is(':checked');

  if (isMultiAnswer) return;
  $('.instruction_test_question_answers_points input:checked').first().trigger('change');
});


$(document).on('change', '.instruction_test_question_answers_points input', function () {
  if ($('#instruction_test_question_is_multi_choice_answers').is(':checked')) {
    return;
  }

  var $this = $(this),
      $answersWrapper = $this.closest('.cb-instruction-test-answers-wrapper'),
      $answerInputs = $answersWrapper.find('.instruction_test_question_answers_points');

  $answerInputs.not($this.closest('.instruction_test_question_answers_points')).each(function() {
    let $mdcSwitch = $(this).find('.mdc-switch');

    if ($mdcSwitch.length && $mdcSwitch[0].mdcSwitch) {
      $mdcSwitch[0].mdcSwitch.foundation.setChecked(false);
    } else {
      $mdcSwitch.find('input').prop('checked', false);
    }
  });
});


$(document).on('click', '.cb-instruction-test-answers-wrapper .add_fields', function(e){
  var $this = $(this),
      timeoutId;

  if (timeoutId) clearTimeout(timeoutId);
  timeoutId = setTimeout(function(){
    changeTestQuestionAnswers($this);
  }, 100);
});

function changeTestQuestionAnswers($this, isAsImages){
  var $form = $this.closest('form'),
      isAsImages = isAsImages || $form.find('#instruction_test_question_is_answers_as_images').is(':checked'),
      $answers = $form.find('.nested-fields.instruction-test-answer');

  $answers.find('instruction-test-answer-input').show().find('input').prop('disabled', false);
  $answers.find('.cb-instruction-test-answer-image').toggle(isAsImages).find('input').prop('disabled', !isAsImages);

  // Values (mdc text fields)
  var $valueInputs = $answers.find('.cb-instruction-test-answer-value');

  $valueInputs.toggle(!isAsImages);
  $valueInputs.each(function() {
    let $field = $(this).find('.mdc-text-field');

    if ($field.length && $field[0].mdcTextField) {
      $field[0].mdcTextField.foundation.setDisabled(isAsImages);
    } else {
      $field.find('input').prop('disabled', isAsImages);
    }
  });
};

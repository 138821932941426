import consumer from "./consumer"
const snackbar = require("../src/scripts/common/snackbar");
const form = require("../src/scripts/common/form");

$(document).on('page:change', function() {
  if ($('body').hasClass('import_form')) {
    consumer.subscriptions.create("ImportChannel", {
      connected() {
        // Called when the subscription is ready for use on the server
        console.log("Connected to the importer!");
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel

        let status = data.status,
            extra  = data.extra;

        if (status == 'in_progress') {
          if (data.html) {
            if (data.klass == 'CustomerEmployee') {
              $('#import_diff').html(data.html)
              app.initialize($('#import_diff'));
              if ($('#import_tabs').length) { activateImportTab(0) }
              $('#import_form_container').toggle(data.show_submit)
              $("#import_preview_container").hide();
            }
          }

          if (data.progress) {
            let current = data.progress.current,
                total   = data.progress.total,
                ratio   = data.progress.ratio;

            if (current == 1) {
              let $saveBtn = $('#upload_import_file'),
                  $form = $saveBtn.closest('form');
              form.remoteSaveDisableWithText($form, $saveBtn);
              $("#import_diff").html('');
            }

            $('#import_progress .current').html(current)
            $('#import_progress .total').html(total)
            $('#import_progress .progressbar')[0].mdcLinearProgress.progress = ratio
          }

          if (data.files) {
            let currentFile = data.files.current,
                totalFiles = data.files.total;

            $('#import_active_file').html(data.files.active_file);
            $('#import_files_progress .current').html(currentFile)
            $('#import_files_progress .total').html(totalFiles)
          }
        } else if (status == 'done') {
          const currentFile = data.files.current,
                totalFiles = data.files.total,
                isLastFile = currentFile == totalFiles;

          if (isLastFile && $('#return_from_import').length) {
            window.location.href = $('#return_from_import').attr('href');
          }
        }
      }
    })
  }
})

$(document).on('MDCTabBar:activated', '#import_tabs', function (e) {
  let index = e.detail.index;
  activateImportTab(index)
})

function activateImportTab(index) {
  let tab = $('.mdc-tab-content').eq(index),
      tabBar = $('#import_tabs')[0];
  $('.mdc-tab-content').hide();
  tab.show()
  if (tabBar) {
    tabBar.mdcTabBar.activateTab(index)
  }
}
